import axios from '@/libs/axios'

class Audit {
  static bannedList(params) {
    return axios.post('user/audit/banned-list', params)
  }

  static banHistoryList(params) {
    return axios.post('user/audit/ban-history-list', params)
  }
}

export default Audit
